import "./App.css";
import "./fonts/inter.css";
import { ThemeProvider } from "theme-ui";
import { Theme } from "./theme";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { VitalLinkContainer } from "./pages/VitalLinkContainer";
import { CompletePage } from "./pages/CompletePage";
import { VitalMockLinkContainer } from "./pages/VitalLinkMockContainer";
import { IFrameTestEmbed, IFrameTestPage, IFrameTestPopup, IFrameTestPopupSuccess } from "./pages/IFrameTestPage";

function App() {
  return (
    <ThemeProvider theme={{ ...Theme }}>
      <Router>
        <Switch>
          <Route exact path="/">
            <VitalLinkContainer />
          </Route>
          <Route exact path="/linked">
            <CompletePage />
          </Route>
          {process.env.NODE_ENV == "development" && <>
            <Route exact path="/mock">
              <VitalMockLinkContainer />
            </Route>
            <Route exact path="/iframe-test">
              <IFrameTestPage />
            </Route>
            <Route exact path="/iframe-test/embed">
              <IFrameTestEmbed />
            </Route>
            <Route exact path="/iframe-test/popup">
              <IFrameTestPopup />
            </Route>
            <Route exact path="/iframe-test/popup-success">
              <IFrameTestPopupSuccess />
            </Route>
          </>}
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
