import { Text, Box, Image, Flex, Button } from "theme-ui";

const ErrorImageContent = () => (
  <Image
    src={"/images/error.svg"}
    width={140}
    sx={{ textAlign: "center", mt: 40 }}
  />
);

const ErrorContent = ({ message }: { message?: string; }) => (
  <Box sx={{ textAlign: "center", mt: 40 }}>
    <Text
      sx={{
        fontSize: "1.3rem",
        fontWeight: 600,
        mb: "15px",
        color: "#111111",
        lineHeight: 1.5,
      }}
    >
      Error
    </Text>
    <Text
      sx={{
        fontSize: 2,
        fontWeight: 300,
        textAlign: "center",
        color: "#383838",
        lineHeight: 1.5,
      }}
    >
      {message ?? "Failed to connect to device, if this problem persists please contact support. You can now head back to the app."}
    </Text>
  </Box>
);

export const ErrorClose = () => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        px: 4,
        py: 4,
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <ErrorImageContent />
        <ErrorContent />
      </Box>
    </Flex>
  );
};

export const ErrorCloseWithButton = ({
  onContinue,
  isMobile,
  showCloseButton,
  message,
}: {
  onContinue: () => void;
  isMobile?: boolean;
  showCloseButton: boolean;
  message?: string;
}) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        px: 4,
        py: 4,
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <ErrorImageContent />
        <ErrorContent message={message} />
      </Box>

      {showCloseButton && (
        <Button
          sx={{ width: "100%" }}
          onClick={onContinue}
          mb={isMobile ? 50 : 0}
        >
          Continue
        </Button>
      )}
    </Flex>
  );
};

interface ErrorProps {
  onContinue: () => void;
  isMobile?: boolean;
}
export const Error: React.FunctionComponent<ErrorProps> = ({
  onContinue,
  isMobile,
}) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        px: 4,
        py: 4,
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ textAlign: "center", width: "100%" }}>
        <ErrorImageContent />
        <ErrorContent />
      </Box>
      <Button sx={{ width: "100%" }} onClick={onContinue}>
        Continue
      </Button>
    </Flex>
  );
};
