import { Text } from "theme-ui";

import { link_error_consumer_facing_copy } from "../lib/link_errors";

export const ErrorText: React.FC<{ error_type: string, status?: number }> = (error) => {
  return (
    <Text sx={{ color: "red", fontSize: 1, textAlign: "center" }}>
      {link_error_consumer_facing_copy(error.error_type, error.status)}
    </Text>
  );
};
